import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <section className="default-top">
      <div className="container">
        <h2>404: Not found</h2>
      </div>
    </section>

    <section className="subpage-icons">
      <div className="container-fluid">
        <Link to="/" className="btn-custom btn-custom--green">
          Back to home page
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
